<template>
  <v-tooltip
    v-bind="{ color: 'primary', 'allow-overflow': false, ...tooltipProps }"
  >
    <template v-slot:activator="{ on }">
      <div v-on="on">
        <v-btn
          v-bind="{ color: 'primary', text: true, ...btnProps }"
          :class="btnClass"
          @click="$emit('click')"
        >
          <span v-if="btnTextSide === 'left'">{{ btnText }}</span>
          <v-icon v-if="iconProps" v-bind="iconProps">
            {{ iconProps.icon }}</v-icon
          >
          <span v-if="btnTextSide === 'right'">{{ btnText }}</span>
        </v-btn>
      </div>
    </template>
    <v-sheet
      color="transparent"
      width="100%"
      v-html="tooltipText"
      class="text-no-wrap secondary--text"
    ></v-sheet>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    btnClass: {
      type: Array
    },
    btnProps: {
      type: Object,
      default: () => ({
        color: 'primary',
        text: true
      })
    },
    btnText: {
      type: String,
      required: false
    },
    btnTextSide: {
      type: String,
      required: false,
      default: () => 'left'
    },
    iconProps: {
      type: Object,
      default: () => ({}),
      required: false
    },
    tooltipProps: {
      type: Object,
      default: () => ({
        top: true,
        disabled: true
      })
    },
    tooltipText: {
      type: String
    }
  }
}
</script>

<style></style>
